import React from "react";
import { Navigate } from "react-router-dom";

import LeadsPage from '../pages/leads'
import LeadPage from "../pages/leads/lead";
import UsersPage from '../pages/users'
import LoginPage from '../pages/login'
import OfficesPage from "../pages/offices";
import UserPage from "../pages/users/user";
import FilesPage from "../pages/files";
import CreateUserPage from "../pages/users/create";
import CreateOfficePage from "../pages/offices/create";
import OfficePage from "../pages/offices/office";
import Import from "../pages/files/import";
import DeskPage from "../pages/desk";


const authProtectedRoutes = [
  { path: "/leads", component: <LeadsPage /> },
  { path: "/leads/:leadId", component: <LeadPage /> },

  { path: "/users", component: <UsersPage /> },
  { path: "/users/:userId", component: <UserPage /> },
  { path: "/users/create", component: <CreateUserPage /> },

  { path: "/offices", component: <OfficesPage /> },
  { path: "/offices/:officeId", component: <OfficePage /> },
  { path: "/offices/create", component: <CreateOfficePage /> },

  { path: "/offices/:officeId/:deskId", component: <DeskPage /> },

  { path: "/files", component: <FilesPage /> },
  { path: "/files/import", component: <Import /> },
  
  // { path: "/", component: <Dashboard /> },
];



const publicRoutes = [
  { path: "/login", component: <LoginPage /> },
  { path: "*", component: <Navigate to="/login" /> },
];

export { authProtectedRoutes, publicRoutes };