import React, { useEffect } from "react";
import Chart from "react-apexcharts";
import { bankColor2 } from "../../helpers/info";

const Columns = ({data = {
    "No answer": 0,
    "Answer": 0,
    "Call again": 0,
    "Not Interested": 0,
    "Wrong Details": 0,
    "In progress": 0,
    "Meeting": 0,
    "Deposit": 0
}}) => {

    delete data["New"];


  const colors = Object.keys(data).map(item => bankColor2[item]);
  const options = {
    series: [{
      data: Object.values(data)
    }],
    chart: {
      type: 'bar',
      events: {
        click: function(chart, w, e) {
          // console.log(chart, w, e)
        }
      },
      toolbar: {
        show: true,
        tools: {
          download: false
        }
      }
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: '45%',
        distributed: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    legend: {
      show: false
    },
    xaxis: {
      categories: Object.keys(data),
      labels: {
        style: {
          colors: colors,
          fontSize: '12px'
        }
      }
    }
  };

  return (
    <div className="app">
      <div className="row">
        <div className="mixed-chart">
          <Chart
            options={options}
            series={options.series}
            type={options.chart.type}
            width="100%"
            height='300px'
          />
        </div>
      </div>
    </div>
  );
};

export default Columns;
