// country
// access_level, office

import Select from 'react-select';


const SelectStatus = ({selected, setSelected}) => {
    const data = [
        { value: 'New', label: 'New'},
        { value: 'Call again', label: 'Call again'},
        { value: 'No answer', label: 'No answer'},
        { value: 'Answer', label: 'Answer'},
        { value: 'Not Interested', label: 'Not Interested'},
        { value: 'Deposit', label: 'Deposit'},
        { value: 'In progress', label: 'In progress'},
        { value: 'Meeting', label: 'Meeting'},
        { value: 'Wrong Details', label: 'Wrong Details'},
    ];


    

    const handleListChange = (selectedOptions) => {
        setSelected(selectedOptions);
    };
    
    return (
        <div className='text-gray-800 text-[14px]'>
            <Select
                defaultValue={selected}
                onChange={handleListChange}
                isMulti
                closeMenuOnSelect={false}
                name="colors"
                options={data}
                className="basic-multi-select"
                classNamePrefix="select"
            />
        </div>
    )
}


export default SelectStatus