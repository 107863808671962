import { MdPhoneInTalk } from "react-icons/md";
import SelectCountryNum from "../Components/handle_select_one/SelectCountryNum";
import { useNavigate } from "react-router-dom";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaUserDoctor } from "react-icons/fa6";
import { CiStreamOff } from "react-icons/ci";
import { useRecoilState } from "recoil";
import { currentUser0, green0, nameLeadCall0, selectedNumber0, status0, callHis0 } from "../helpers/states";
import { useSocket } from "../Routes/SocketProvider";
import { Store } from 'react-notifications-component';
import { fetchHistoryCall, getLeadName, hangup } from "../helpers/funcs";
import { RiHistoryFill } from "react-icons/ri";
import { FiPhoneOff } from "react-icons/fi";




const Header = ({popCall, setPopCall}) => {
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [status, setStatus] = useRecoilState(status0)
    const socket = useSocket();
    const [nameLeadCall, setNameLeadCall] = useRecoilState(nameLeadCall0)
    const [green, setGreen] = useRecoilState(green0)
    const Navigate = useNavigate()
    const [callList, setCallList] = useRecoilState(callHis0)


    useEffect(() => {
        if (status !== 2) {
            setNameLeadCall({name: '. . .'})
        }
    }, [status])

    useEffect(() => {
        if (currentUser?.['office_id'] && currentUser?.['extension'] && socket !== null) {
            socket?.emit('join_room', { room: currentUser?.['office_id'] + '_' + currentUser?.['extension'] });
        }
    }, [currentUser, socket])

    useEffect(() => {
        // Handle incoming messages
        socket?.on('extension_status', (data) => {
            setStatus(data.status)
        });

        socket?.on('stop_dialer', () => {
            console.log('aaaa123')
            setGreen(0)
        });

        socket?.on('message', (data) => {
            Store.addNotification({
                message: data?.message,
                type: data?.type,
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        });


        socket?.on('active_call', async (data) => {
            var name = await getLeadName(data.lead);
            setNameLeadCall({"name": name, id: data.lead});


            fetchHistoryCall(setCallList, 0, true)
        });

        
        socket?.on('call_end', async (data) => {
            // console.log(data)
        });


        socket?.on('auto_dialer_status', async (data) => {
            setGreen(data.status ? 1 : 0)
        });
        
    }, [socket])



    

    return (
        <div className="bg-white h-[50px] py-5 flex flex-row justify-between items-center px-4 shadow-md ">
            <div>
                <SelectCountryNum isSm={true} />
            </div>
            <div className="flex flex-row items-center space-x-2.5">
                <motion.div 
                    className={`border-2 px-2 py-1 rounded-full flex flex-row items-center space-x-2 ${status === 2 && 'cursor-pointer  hover:!bg-[#e3cf60]'}`}
                    onClick={() => {
                        if (nameLeadCall?.id) {
                            Navigate(`/leads/${nameLeadCall?.id}`)
                        }
                    }}
                    animate={{
                        scale: status === 2 ? 1.1 : 1,
                        backgroundColor: status === 0 ? "#f98d8d" : status === 1 ? "#4ade80" : "#fde66b"
                    }}
                    transition={{ duration: 0.5 }}
                >
                    <div className='flex flex-col space-y-6'>
                        <motion.div
                            className='flex justify-center hover:text-white'
                            animate={{ rotate: status === 1 ? 360 : 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            {status === 0 && <CiStreamOff className="text-[23px] text-red-600" />}
                            {status === 1 && <TfiHeadphoneAlt className="text-[23px] text-green-600" />}
                            {status === 2 && <FaUserDoctor className="text-[23px] text-yellow-600" />}
                        </motion.div>
                    </div>
                    <span className={`leading-none relative top-0.5 text-[15px] font-semibold text-gray-600`}>
                        {status === 0 && 'OFFLINE'}
                        {status === 1 && 'ON AIR'}
                        {status === 2 && nameLeadCall?.name || ''}
                    </span>
                </motion.div>

                {
                    (status === 2) && (
                        <motion.div 
                            onClick={hangup}
                            className={`border-2 px-2 h-[35px] rounded-full flex flex-row items-center space-x-2 cursor-pointer bg-red-400 hover:bg-red-500 text-gray-100`}
                            animate={{
                                scale: 1.1,
                                backgroundColor: "#ff4d4d",
                            }}
                            whileHover={{
                                scale: 1.1,
                                backgroundColor: "#e60000",
                            }}
                            transition={{ duration: 0.3 }}
                        >
                            <FiPhoneOff className="text-[18px]" />
                        </motion.div>
                    )
                }
            </div>

            <div className="flex flex-row items-center space-x-1.5">
                <div onClick={() => setPopCall(prev=> !prev)} className={`cursor-pointer ${popCall ? 'bg-blue-400' : 'bg-blue-500'} rounded-full text-white h-[32px] w-[32px] flex items-center justify-center text-[22px]`}>
                    <RiHistoryFill />
                </div>
                <input className="inp w-[60px] text-center text-gray-600" value={currentUser?.['extension'] || 'none'} disabled />
                

                <MainBut green={green} setGreen={setGreen} status={status} />
                {/* <button onClick={handleAuto} className='but but-basic-color bg-green-600 hover:bg-green-700 active:bg-green-800 flex flex-row items-center space-x-1'>
                    <MdPhoneInTalk className='text-[18px]' />
                    <span>AUTO DIAL</span>
                </button> */}

            </div>
        </div>
    )
}

export default Header;




const MainBut = ({ green, setGreen, status }) => {
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [selectedNumber, setSelectedNumber] = useRecoilState(selectedNumber0)

    const Navigate = useNavigate()

    const socket = useSocket();
    
    const handleBut = async (state) => {
        setGreen(state)
        const user_room = currentUser?.['office_id'] + '_' + currentUser?.['extension'];
        if (state === 0) {
            socket?.emit('stop_auto_dialer', {"room": user_room});
        } else {
            const leadsFilter = JSON.parse(localStorage.getItem('leadsFilter'));
            socket?.emit('start_auto_dialer', {"room": user_room, 'user': currentUser?.id, iso: selectedNumber?.value, filters: leadsFilter?.filter});
        }
    }

    useEffect(() => {
        socket?.on('call_lead', (data) => {
            Navigate(`/leads/${data?.lead}` )
        });
    }, [socket])



    return (
        <div className='flex flex-row items-center'>
            <button
                disabled={status === 0}
                onClick={() => handleBut(green === 0 ? 1 : 0)}
                className={`
                    disabled:bg-gray-400 but but-basic-color flex flex-row items-center justify-center space-x-1 w-[100px]
                    ${green === 0 ? 'bg-green-600 hover:bg-green-700 active:bg-green-800' :
                        green === 1 ? 'bg-red-600 hover:bg-red-700 active:bg-red-800' :
                            ''
                    }
                `}
            >
                <div className="flex flex-row items-center space-x-1.5">
                    {
                        green === 0 
                        ?   <MdPhoneInTalk className='text-[20px]' /> 
                        :   <div className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] dark:text-white" role="status"></div>
                    }
                    <span className="text-[18px]">
                        {
                            green === 0 ? 'START' :
                                green === 1 ? 'STOP' : ''
                        }
                    </span>
                </div>
            </button>
        </div>
    )
}

