import {atom} from 'recoil';

export const currentUser0 = atom({
    key: 'currentUser0',
    default: null,
});

export const leadsTable0 = atom({
    key: 'leadsTable0',
    default: {
        "leads": undefined,
        "users": undefined
    },
});

export const pos0 = atom({
    key: 'pos0',
    default: 0,
});

export const ring0 = atom({
    key: 'ring0',
    default: {
        last: null,
        isCallingList: []
    },
});




export const isGreen0 = atom({
    key: 'isGreen0',
    default: false,
});

export const officesList0 = atom({
    key: 'officesList0',
    default: undefined
});

export const desksList0 = atom({
    key: 'desksList0',
    default: undefined
});


export const green0 = atom({
    key: 'green0',
    default: 0
});

export const files0 = atom({
    key: 'files',
    default: null
});



export const forceUpdateTrans0 = atom({
    key: 'forceUpdateTrans0',
    default: 0
});


export const selectedNumber0 = atom({
    key: 'selectedNumber0',
    default: JSON.parse(localStorage.getItem('selectedNumber0')) || undefined
});

export const choosedFiles0 = atom({
    key: 'choosedFiles0',
    default: JSON.parse(localStorage.getItem('choosedFiles')) || []
});


export const selected0 = atom({
    key: 'selected0',
    default: {
        value: '',
        label: '',
        iso: 'aa'
    }
});



export const status0 = atom({
    key: 'status0',
    default: 0
});



export const nameLeadCall0 = atom({
    key: 'nameLeadCall0',
    default: {name: '. . .'}
});




export const callHis0 = atom({
    key: 'callHis0',
    default: undefined
});
