export default {
    // "": "",
    "af": "Afrikaans",
    "sq": "Albanian",
    "eu": "Basque",
    "be": "Belarusian",
    "bg": "Bulgarian",
    "ca": "Catalan",
    "hr": "Croatian",
    "cs": "Czech",
    "da": "Danish",
    "nl": "Dutch",
    "en": "English",
    "et": "Estonian",
    "fo": "Faeroese",
    "fa": "Farsi",
    "fi": "Finnish",
    "fr": "French",
    "gd": "Gaelic",
    "de": "German",
    "el": "Greek",
    "he": "Hebrew",
    "hi": "Hindi",
    "hu": "Hungarian",
    "is": "Icelandic",
    "id": "Indonesian",
    "ga": "Irish",
    "it": "Italian",
    "ja": "Japanese",
    "ko": "Korean",
    "ku": "Kurdish",
    "lv": "Latvian",
    "lt": "Lithuanian",
    "mk": "Macedonian",
    "ml": "Malayalam",
    "ms": "Malaysian",
    "mt": "Maltese",
    "no": "Norwegian",
    "nb": "Norwegian",
    "nn": "Norwegian",
    "pl": "Polish",
    "pt": "Portuguese",
    "pa": "Punjabi",
    "rm": "Rhaeto-Romanic",
    "ro": "Romanian",
    "ru": "Russian",
    "sr": "Serbian",
    "sk": "Slovak",
    "sl": "Slovenian",
    "sb": "Sorbian",
    "es": "Spanish",
    "sv": "Swedish",
    "th": "Thai",
    "ts": "Tsonga",
    "tn": "Tswana",
    "tr": "Turkish",
    "ua": "Ukrainian",
    "ur": "Urdu",
    "ve": "Venda",
    "vi": "Vietnamese",
    "cy": "Welsh",
    "xh": "Xhosa",
    "ji": "Yiddish",
    "zu": "Zulu"
}