import { useEffect, useState } from "react";
import Menu from "./Menu";
import { useRecoilState } from "recoil";
import { desksList0, favorites0, officesList0 } from "../helpers/states";
import { fetchDesks, fetchFavorites, fetchOffices } from "../helpers/funcs";
import Header from "./Header";
import CallHistory from "./CallHistory";

const PrivateLayout = ({ children }) => {
    const [officesList, setOfficesList] = useRecoilState(officesList0);
    const [desksList, setDesksList] = useRecoilState(desksList0);
    const [popCall, setPopCall] = useState(true)

    useEffect(() => {
        const fetchData = async () => {
            setOfficesList(await fetchOffices());
        };
        if (officesList === undefined) {
            fetchData();
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setDesksList(await fetchDesks());
        };
        if (officesList === undefined) {
            fetchData();
        }
    }, []);


    


    return (
        <div className="back-color min-h-screen flex flex-row">
            <Menu />
            <div className="flex flex-col w-[calc(100%-70px)] h-screen overflow-x-hidden relative">
                <Header popCall={popCall} setPopCall={setPopCall} />
                <div className="p-4 flex flex-row space-x-4 overflow-y-hidden">
                    <div className={`min-h-[calc(100vh-100px)] overflow-y-auto ${popCall ? '!w-[calc(100%-270px)]' : 'w-full' }`}>
                        {children}
                    </div>
                    <CallHistory popCall={popCall} setPopCall={setPopCall} />
                </div>
            </div>
        </div>
    );
};

export default PrivateLayout;
