import Select from 'react-select';
import { desksList0 } from '../../helpers/states';
import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

const SelectDesks = ({selected, setSelected}) => {
    const [desksList, setDesksList] = useRecoilState(desksList0)
    const [data, setData] = useState([])


    
    useEffect(() => {
        if (desksList !== undefined) {
            setData(
                desksList.map(office => {
                    return { value: office.id, label: office.name}
                })
            )
        }
    }, [desksList])

    const handleListChange = (selectedOptions) => {
        setSelected(selectedOptions);
    };
    
    return (
        <div className='text-gray-800 text-[14px]'>
            <Select
                defaultValue={selected}
                onChange={handleListChange}
                isMulti
                closeMenuOnSelect={false}
                name="colors"
                options={data}
                className="basic-multi-select"
                classNamePrefix="select"
            />
        </div>
    )
}


export default SelectDesks