import { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const PublicLayout = ({children}) => {
    const navigate = useNavigate();


    useEffect(() => {
        if (localStorage.getItem('authToken')) {
            navigate('/leads');
        }
    }, [navigate]);

    return (
        <div>
            {children}
        </div>
    )
}

export default PublicLayout;