import { BsTelephoneFill } from 'react-icons/bs';
import { get } from '../helpers/apiHandler';
import { useEffect, useRef, useState } from 'react';
import Skeleton from 'react-loading-skeleton'
import { FaPlusCircle } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { FaStar } from 'react-icons/fa6';
import { useRecoilState } from 'recoil';
import { CiStar } from 'react-icons/ci';
import { useFavorites } from '../Routes/FavoritesProvider';
import Ring from '../Components/Ring';
import { callHis0 } from '../helpers/states';
import { fetchHistoryCall } from '../helpers/funcs';


const CallHistory = ({popCall, setPopCall}) => {
    const [data, setData] = useRecoilState(callHis0)
    const first = useRef()
    const Navigate = useNavigate()
    const { favorites, handleFavorite } = useFavorites();


    useEffect(() => {
        if (first.current !== true) {
            first.current = true
            fetchHistoryCall(setData, 0)
        }
    }, [])

    const moreHistory = () => {
        fetchHistoryCall(setData, data.length)
    }


    if (popCall) {
        return (
            <div className="flex pl-4 flex-col !w-[270px] space-y-4 border-l-2 overflow-y-auto">
                <div className="space-y-4">
                    {
                        data?.length === 0 && (
                            <h2 className='text-lg text-gray-800 font-semibold'>No history call</h2>
                        )
                    }
                    {
                        data?.map((log, l) => {
                            
                            
                            return (
                            <div key={l} className="card">
                                <div className='flex flex-col'>
                                    <div className='flex flex-row items-center space-x-1'>
                                        <div onClick={() => Navigate(`/leads/${log?.subject?.id}`)} className='hover:underline font-semibold cursor-pointer'>
                                            {log?.subject?.first_name} {log?.subject?.last_name}
                                        </div>
                                        <div className="text-blue-500 relative bottom-[1px]" onClick={() => {
                                            handleFavorite(log?.subject?.id)
                                        }}>
                                            {
                                                typeof favorites?.[log?.subject?.id] !== typeof undefined ? (
                                                    <FaStar />
                                                ) : (
                                                    <CiStar />
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className='flex flex-row justify-between items-center w-full'>
                                        <div className='text-gray-700'>{log?.created_at}</div>
                                        <Ring uid={log?.subject?.id} />
                                        {/* <button onClick={() => {}} className={`rounded-full text-green-500 w-6 h-6 flex items-center justify-center`}>
                                            <BsTelephoneFill className="text-[16px]" />
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        )})
                    }
                    {
                        data === undefined && (
                            <div className="card">
                                <div className='flex flex-col'>
                                    <div className='hover:underline font-semibold cursor-pointer'><Skeleton className='w-[65px]' /></div>
                                    <div className='flex flex-row justify-between items-center w-full'>
                                        <div className='text-center text-gray-700'><Skeleton className='w-[120px]' /></div>
                                        <Skeleton className='w-6 h-6' circle/>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                {
                    data?.length > 0 && (
                        <div className='w-full flex justify-center'>
                            <FaPlusCircle onClick={moreHistory} className='text-[30px] cursor-pointer text-blue-400 hover:text-blue-500 active:text-blue-600' />
                        </div>
                    )
                }
            </div>
        )
    }
}

export default CallHistory