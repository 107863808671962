import { DateRange } from 'react-date-range';
import {useEffect, useState} from 'react'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { formatDate } from '../helpers/funcs';

const PickDateRange = ({dateRange, setDateRange, doIt, setPageCount}) => {
    const defaultDates = {
        'startDate': new Date(dateRange['startDate']),
        'endDate': new Date(dateRange['endDate']),
        'key': 'selection'
    }

    const [range, setRange] = useState([defaultDates]);
    const [show, setShow] = useState(false)

    const handleDates = async () => {
        setShow(false)
        setDateRange(prev => {
            return {
                ...prev,
                startDate: formatDate(range[0]['startDate']),
                endDate: formatDate(range[0]['endDate']),
            }
        })
        await new Promise(resolve => setTimeout(resolve, 500));
        doIt.current = true
        setPageCount(0)
    }

    useEffect(() => {
        if (show) {
            setRange([defaultDates])
        }
    }, [show])

    const [str, setStr] = useState('-')
    useEffect(() => {
        setStr(getStr(dateRange['startDate'], dateRange['endDate']))
    }, [dateRange])



    const randomId = 'ranePop'
    useEffect(() => {
        if (show) {
            const handleBodyClick = (e) => {
                var element = e.target
                var isOut = true;
                while (true) {
                    if (element.id == randomId || element.id == `but-${randomId}`) {
                        isOut = false;
                        break;
                    };
                    element = element.parentElement
                    if (element == null) break;
                }
                if (isOut) setShow(false);
            };
                document.body.addEventListener('click', handleBodyClick);
            return () => {
                document.body.removeEventListener('click', handleBodyClick);
            };
        }
    }, [show])

    

    const getStr = (start, end) => {
        var text1 = ''
        var text2 = ''
        for (var x of [0,1,2]) {
            if (start.split('-')[x] !== end.split('-')[x]) {
                text1 += start.split('-')[x]
                text2 += end.split('-')[x]
                if (x !== 2) {
                    text1 += '/' 
                    text2 += '/' 
                }
            }
        }
        var ret = text1 + '-' + text2
        if (ret !== '-') return ret;
        return start.replace(/-/g, "/");
    }
    


    return (
        <div className=''>
            <button className='but but-basic' onClick={() => setShow(prev => !prev)} id={`but-${randomId}`}>
                {str}
            </button>
            {
                show && (
                    <div className='relative top-2' id={`${randomId}`}>
                        <div className='absolute z-30 card p-0 border'>
                            <DateRange
                                editableDateInputs={true}
                                onChange={item => setRange([item.selection])}
                                moveRangeOnFirstSelection={true}
                                ranges={range}
                            />
                            <div className='flex flex-row space-x-2 justify-end p-2 pt-0'>
                                <button onClick={() => setShow(false)} className='but but-basic-emp'>Cancel</button>
                                <button onClick={handleDates} className='but but-basic'>Apply</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}


export default PickDateRange