import { useRecoilState } from "recoil";
import { currentUser0, ring0, selectedNumber0 } from "../helpers/states";
import { Store } from 'react-notifications-component';
import { get } from "../helpers/apiHandler";
import { BsTelephoneFill } from 'react-icons/bs';
import { BsFillTelephoneInboundFill } from "react-icons/bs";
import { useEffect } from "react";


const Ring = ({uid}) => {
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [handleRing, setHandleRing] = useRecoilState(ring0)
    const [selectedNumber, setSelectedNumber] = useRecoilState(selectedNumber0)

    const makeCall = async () => {
        Store.addNotification({
            message: "Ringing to lead...",
            type: 'success',
            insert: "bottom",
            container: "bottom-right",
            dismiss: {
              duration: 10000,
            }
        });
        setHandleRing(prev => {
            return {
                ...prev,
                isCallingList: [...prev?.isCallingList, uid],
                last: uid
            }
        })
        const res = await get(`/lead/${uid}/call`, {callerid: currentUser.callerid, country: selectedNumber?.value})
        if (res.ok) {
            // var message = "No answer"
            // var msg_type = "danger";
            // if (res.data.connected) {
            //     message = "Call established successfully";
            //     msg_type = "success";
            // } else if (res.data.answer) {
            //     message = "Call failed to connect";
            // }
            // Store.addNotification({
            //     message: message,
            //     type: msg_type,
            //     insert: "bottom",
            //     container: "bottom-right",
            //     dismiss: {
            //       duration: 5000,
            //     }
            // });
            
        } else {
            Store.addNotification({
                message: res.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 10000,
                }
            });
        }
        setHandleRing(prev => {
            return {
                ...prev,
                isCallingList: prev?.isCallingList.filter(list => list !== uid),
            }
        })
    }

    const maxCalling = 5;

    return (
        <button
            disabled={handleRing.isCallingList.includes(uid) || handleRing.isCallingList.length >= maxCalling} 
            onClick={() => makeCall()} 
            className={`flex items-center justify-center w-7 h-7  border rounded-full
                ${handleRing.isCallingList.includes(uid)
                    ? uid === handleRing?.last // disabled
                        ? 'bg-[#405189] text-white border-[#405189] animate-pulse' // call & last
                        : 'bg-[#405189] text-white border-[#405189] animate-pulse' // call & no-last
                    : uid === handleRing?.last
                        ? 'text-[#405189] border-[#405189] bg-[#405189] text-white active:bg-[#39487b]' // no-call & last
                        : handleRing.isCallingList.length >= maxCalling // no-call & no-last
                            ? 'text-[#405189] border-[#405189] bg-white'
                            : 'text-[#405189] border-[#405189] bg-white hover:bg-[#405189] hover:text-white active:bg-[#39487b]'
                }
            `}>
            {
                handleRing.isCallingList.includes(uid) && uid === handleRing?.last ? (
                    //  animate-bounce animate-pulse animate-spin
                    <BsFillTelephoneInboundFill className="text-[14px]" />
                ) : (
                    <BsTelephoneFill className="text-[14px]" />
                )
            }
        </button>
    )
}


export default Ring