import React from 'react';
import Routes from './Routes';


function App() {
  return (
    <React.Fragment>
      <Routes />
    </React.Fragment>
  );
}
export default App;
