import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { formDataToObject, updateLead } from '../../../helpers/funcs';
import { post } from '../../../helpers/apiHandler';
import { Store } from 'react-notifications-component';
import { forceUpdateTrans0 } from '../../../helpers/states';
import { useRecoilState } from 'recoil';


const AddTran = ({update_lead}) => {
    let { leadId } = useParams();
    const [show, setShow] = useState(false)
    const randomId = 'ranePop'
    
    useEffect(() => {
        if (show) {
            const handleBodyClick = (e) => {
                var element = e.target
                var isOut = true;
                while (true) {
                    if (element.id == randomId || element.id == `but-${randomId}`) {
                        isOut = false;
                        break;
                    };
                    element = element.parentElement
                    if (element == null) break;
                }
                if (isOut) setShow(false);
            };
                document.body.addEventListener('click', handleBodyClick);
            return () => {
                document.body.removeEventListener('click', handleBodyClick);
            };
        }
    }, [show])

    const [date, setDate] = useState(new Date().toISOString().substr(0, 10));
    const [forceUpdateTrans, setForceUpdateTran] = useRecoilState(forceUpdateTrans0);
    const handleDateChange = (event) => {
        setDate(event.target.value);
    };

    const createTran = async (e) => {
        e.preventDefault()
        const formData = new FormData(e.target);
        const objData = formDataToObject(formData);
        const result = await post(`/lead/${leadId}/transaction`, objData);

        setForceUpdateTran(prev => prev + 1);
        update_lead('status', 'Deposit')
        if (result.ok) {
            Store.addNotification({
                message: result.data.message || `Transaction created successfully`,
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
            // setSelectedStatus('Deposit')
        } else {
            Store.addNotification({
                message: result.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                  duration: 5000,
                }
            });
        }


        setShow(false)
    }


    return (
        <div>
            <button className={`but but-basic flex flex-row items-center space-x-1`} onClick={() => setShow(prev => !prev)} id={`but-${randomId}`}>
                {/* <BiFilterAlt /> */}
                <span className='font-semibold tracking-wide'>+ Add transaction</span>
            </button>

            {
                show && (
                    <div className='relative top-1' id={`${randomId}`}>
                        <form className='absolute z-30 card p-2 border space-y-2' onSubmit={createTran}>
                            <input type='date' name="date" className='inp' value={date} onChange={handleDateChange} />
                            <input type='number' name="amount" className='inp' placeholder='amount' required />
                            <div className='flex flex-row space-x-2'>
                                <button onClick={() => setShow(false)} className='but but-basic-emp w-1/2'>Cancel</button>
                                <button type='submit' className='but but-basic  w-1/2'>Apply</button>
                            </div>
                        </form>
                    </div>
                )
            }

        </div>
    )
}

export default AddTran