

import React, { useState } from 'react'
import { formDataToObject } from '../../helpers/funcs';
import { useNavigate } from 'react-router-dom';
import { post } from '../../helpers/apiHandler';

function LoginPage() {
    const [errText, setErrText] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();

  const handleLog = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    const formData = new FormData(e.target);
    const { email, password } = formDataToObject(formData);
    const result = await post('/user/login', { email, password });
    if (result.ok) {
      localStorage.setItem('authToken', result.data.token);
      navigate('/leads')
    } else {
      setErrText('Error during login, please try again.');
    }
    setIsLoading(false);
  };

  return (
    <div className='min-h-screen flex flex-col justify-center items-center back-color'>

        <img src='/best-logo.png' className='h-[210px]' />
        <form className='card space-y-4 w-full max-w-[350px]' onSubmit={handleLog}>
          <div className='flex flex-col space-y-4 text-gray-700'>
            <div className='flex flex-col space-y-1'>
              <span className='text-xs'>EMAIL</span>
              <input name='email' className='inp' type='email' required />
            </div>
            <div className='flex flex-col space-y-1'>
              <span className='text-xs'>PASSWORD</span>
              <input name='password' className='inp' type='password'required />
            </div>
          </div>
          <div className='flex flex-row justify-between items-center'>
            <span className='text-red-600'>
              {errText}
            </span>
            <button type='submit' className='but but-basic'>Log in</button>
          </div>
        
        </form>
    </div>
  )
}


export default LoginPage;