// country
// access_level, office

import { useEffect } from 'react';
import Select from 'react-select';
import { currentUser0 } from '../../helpers/states';
import { useRecoilState } from 'recoil';
import accessLevel from '../../docs/accessLevel';

const SelectLevels = ({selected, setSelected}) => {
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    var data = []
    for (var x=0; x<currentUser.access_level; x++) {
        data.push(accessLevel[x])
    }
    
    const handleListChange = (selectedOptions) => {
        setSelected(selectedOptions);
    };

    return (
        <div className='text-gray-800 text-[14px]'>
            <Select
                defaultValue={selected}
                onChange={handleListChange}
                isMulti
                closeMenuOnSelect={false}
                name="colors"
                options={data}
                className="basic-multi-select"
                classNamePrefix="select"
            />
        </div>
    )
}


export default SelectLevels