

const Loading = () => {

    return (
        <div className="min-h-screen max-w flex items-center justify-center back-color">
            <img src="/best-logo.png" className="animate-pulse" />
        </div>
    )
}

export default Loading