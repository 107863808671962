import Select from 'react-select';
import isoToCountry from '../../docs/isoToCountry'
import { useEffect } from 'react';


const SelectCountry = ({selected, setSelected}) => {

    var data = Object.keys(isoToCountry).map(isoCode => {
        return {value: isoCode, label: isoToCountry[isoCode]}
    })
    
    // const data = [
    //     { value: 'DK', label: 'Germany'},
    // ];

    const handleListChange = (selectedOptions) => {
        setSelected(selectedOptions);
    };

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          backgroundColor: '#f3f4f6',
        }),
        menuList: base => ({
            ...base,
            maxHeight: "200px"
        })
    };
    
    return (
        <div className='text-gray-800 text-[14px]'>
            <Select
                defaultValue={selected}
                onChange={handleListChange}
                isMulti
                closeMenuOnSelect={false}
                styles={customStyles}
                options={data}
                className="basic-multi-select"
                classNamePrefix="select"
            />
        </div>
    )
}


export default SelectCountry