import Select from 'react-select';
import IsoToLang from '../../docs/IsoToLang';
import { useEffect } from 'react';


const SelectLangOne = ({selected, setSelected}) => {
    const data = Object.keys(IsoToLang).map(isoCode => {
        return {value: isoCode, label: IsoToLang[isoCode]}
    })

    const handleListChange = (selectedOptions) => {
        setSelected(selectedOptions.value);
    };

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          backgroundColor: '#f3f4f6', // Change background color here
        }),
        menuList: base => ({
            ...base,
            maxHeight: "200px" // your desired height
        })
    };

    // useEffect(() => {
    //     console.log(selected)
    // }, [selected])
    
    return (
        <div className='text-gray-800 text-[14px]'>
            <Select
                onChange={handleListChange}
                closeMenuOnSelect={true}
                styles={customStyles}
                name="colors"
                options={data}
                className="basic-single min-w-[130px]"
                classNamePrefix="select max-h-10"
                defaultValue={{value: selected || '', label: IsoToLang[selected] || ''}}
                isClearable={false}
                isRtl={false}
                isSearchable={true}
            />








            {/* <select onChange={handleListChange} value={selected} className="inp px-1 min-w-[120px]">
                {
                    data.map((item, i) => (
                        <option key={i} value={item.value}>
                            {item.label}
                        </option>
                    ))
                }
            </select> */}
        </div>
    )
}


export default SelectLangOne