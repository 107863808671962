import { useEffect, useState, useRef } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import Table from '../../Components/handle_table/Table';
import { Link, useNavigate } from 'react-router-dom';
import BubbleFilters from '../../Components/handle_table/BubbleFilters';
import { get, post } from '../../helpers/apiHandler';
import { FaPlus } from "react-icons/fa6";
import { choosedFiles0, currentUser0, green0, leadsTable0 } from '../../helpers/states';
import { useRecoilState } from 'recoil';
import { MdPhoneInTalk } from 'react-icons/md';
import PickDateRange from '../DateRange';
import { formatDate } from '../../helpers/funcs';
import { CiStar } from "react-icons/ci";
import { FaStar } from "react-icons/fa";

const TableLayout = ({allThs, objBase, fetchURL, tableType, title, scroledDiv, ButPop, update_lead}) => {
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    const Navigate = useNavigate()
    const [leadsTable, setLeadsTable] = useRecoilState(leadsTable0)
    const [data, setData] = useState(tableType === 0 ? leadsTable['leads'] : tableType === 1 ? leadsTable['users'] : undefined)
    const [startPage, setStartPage] =   useState(tableType === 0 
                                            ? leadsTable['leads'] !== undefined 
                                                ?   leadsTable['leads'].startPage
                                                :   1
                                        : tableType === 1
                                            ? leadsTable['users'] !== undefined
                                                ?   leadsTable['users'].startPage
                                                :   1
                                        : 1 )

    const [pageCount, setPageCount] = useState(
        tableType === 0 
            ? leadsTable['leads']?.current_page === undefined 
                ? 1 
                : leadsTable['leads'].current_page + 1
            : tableType === 0 
                ? leadsTable['leads']?.current_page === undefined ? 1 
                : leadsTable['leads'].current_page + 1 
        : tableType === 1
                ? leadsTable['users']?.current_page === undefined 
                ? 1 
                : leadsTable['users'].current_page + 1
            : tableType === 0 
                ? leadsTable['users']?.current_page === undefined ? 1 
                : leadsTable['users'].current_page + 1  
        
        : 1
        )
    const [filterParam, setFilterParam] = useState(objBase)
    

    const [dateRange, setDateRange] = useState({startDate: formatDate(new Date()), endDate: formatDate(new Date())})

    useEffect(() => {
        if (startPage === 0) {
            setStartPage(1)
        } else if (data !== undefined) {
            if (startPage > data?.last_page) {
                setStartPage(data?.last_page)
            }
        }
    }, [startPage])

    const [objFilter, setObjFilter] = useState(
        tableType == 0 
        ? localStorage.getItem('leadsFilter') !== null 
            ? JSON.parse(localStorage.getItem('leadsFilter')) 
            : {
                sort_by: 'id',
                sort_direction: 'asc',
                per_page: 50,
                filter: JSON.stringify(objBase)
            }
        :
        tableType == 1
        ? localStorage.getItem('usersFilter') !== null 
            ? JSON.parse(localStorage.getItem('usersFilter')) 
            : {
                sort_by: 'id',
                sort_direction: 'asc',
                per_page: 50,
                filter: JSON.stringify(objBase)
            }
        : {
            sort_by: 'created_at',
            sort_direction: 'desc',
            per_page: 50,
            filter: JSON.stringify(objBase)
        }
    )

    useEffect(() => {
        setFilterParam(JSON.parse(objFilter.filter))
    }, [objFilter])
    
    useEffect(() => {
        if (tableType === 0) {
            localStorage.setItem('leadsFilter', JSON.stringify(objFilter))
        } else if (tableType === 1) {
            localStorage.setItem('usersFilter', JSON.stringify(objFilter))
        }
    }, [objFilter])



    const doIt = useRef(true)
    // const scroledDiv = useRef(null);
    const scroledDiv2 = useRef(null);
    const isready = useRef(false);

    const fetchLeads = async (pageIndex) => {
        console.log('fetch: ', pageIndex)
        var result;
        if (fetchURL === '/leads') {
            result = await get(fetchURL, {...objFilter, ...dateRange, page: pageIndex})
        } else {
            result = await get(fetchURL, {...objFilter, ...dateRange, page: pageIndex})
        }
        if (result.ok) {
            const response = result.data;
            var sor = response.data
            var to = response.to;
            var obj = {
                data: sor,
                total: response.total,
                from: response.from,
                to: to,

                current_page: response.current_page,
                last_page: response.last_page,
                startPage: startPage,

                total_transaction_sum: response.total_transaction_sum
            }

            setData(prev => {
                if (typeof prev == typeof undefined) {
                    return obj
                } else {
                    return {
                        ...obj,
                        from: prev.from,
                        to: sor.length == 0 ? prev.to : obj.to,
                        data: [...prev.data, ...obj.data]
                    }
                }
            })
        } else {
            setData({
                startPage: startPage,
                last_page: 0,
                current_page: 0,
                data: [],
                total: 0,
                from: 0,
                to: 0,
            })
        }
        isready.current = true
    }


    useEffect(() => {
        if (data?.data.length >= 50) {
            const container = scroledDiv ? scroledDiv.current : scroledDiv2.current;
            if (container) {
                const { scrollHeight, clientHeight } = container;
                const hasScroll = scrollHeight > clientHeight;

                if (!hasScroll) {
                    doIt.current = true;
                    setPageCount(prev => prev + 1)
                }
            }
        }
    }, [data])

    const handleScroll = () => {
        const container = scroledDiv ? scroledDiv.current : scroledDiv2.current;
        if (container) {
            if (!(data?.total == data?.to && typeof data?.total !== typeof undefined)) {
                const { scrollTop, scrollHeight, clientHeight } = container;
                if (scrollTop + clientHeight >= scrollHeight - 1300) {
                    if (isready.current) {
                        if (!(scrollTop == 0 && scrollHeight == clientHeight)) {
                            doIt.current = true;
                            isready.current = false;
                            setPageCount(prev => prev + 1)
                        }
                    }
                }
            }
        }
    };

    useEffect(() => {
        const container = scroledDiv ? scroledDiv.current : scroledDiv2.current;
        if (container) container.addEventListener('scroll', handleScroll);
        return () => {if (container) container.removeEventListener('scroll', handleScroll)};
    }, [data]);
    

    
    useEffect(() => {
        if (pageCount == 0) {
            setPageCount(1)
        } else {
            if (doIt.current) {
                if (data === undefined || pageCount <= data?.last_page) {
                    fetchLeads(pageCount)
                    doIt.current = false
                } else {
                    isready.current = true
                }
            }
        }
    }, [pageCount])

    useEffect(() => {
        if (typeof data !== undefined) {
            if (tableType === 0) {
                setLeadsTable((prev) => {return {...prev, "leads": data}})
            } else if (tableType === 1) {
                setLeadsTable((prev) => {return {...prev, "users": data}})
            }
        }
    }, [data])

    const [choosedFiles, setChoosedFiles] = useRecoilState(choosedFiles0)
    const searchFilter = async () => {
        if (tableType === 0) {
            if (filterParam['file_name'].length === 0) {
                setChoosedFiles([])
                localStorage.setItem('choosedFiles', JSON.stringify([]))
            }
        }
        setData();
        setObjFilter(prev => {
            return {
                ...prev,
                filter: JSON.stringify(filterParam)
            }
        })
        await new Promise(resolve => setTimeout(resolve, 500));
        doIt.current = true
        setPageCount(0)
    }

    const isEmp =  () => {
        var bol = objFilter.filter == JSON.stringify(filterParam);        
        return !bol
    }



    ////// AUTO DAILER /////
    const startAuto = () => {
        Navigate('/auto')
    }

    const [isFav, setIsFav] = useState(tableType === 0 && JSON.parse(objFilter.filter)['favorite'][0]?.value ? true : false)

    const handleFav = () => {
        setData()
        setIsFav(prev => !prev)
    }
    
    useEffect(() => {
        if (tableType === 0) {
            var old_val = JSON.parse(objFilter.filter).favorite[0]?.value
            if (!(old_val === undefined && isFav === false) && old_val !== isFav) {
                setObjFilter(prev => {
                    var filtObj = JSON.parse(prev.filter)
                    if (isFav) {
                        filtObj.favorite = [{'value': true}]
                    } else {
                        filtObj.favorite = []
                    }
                    return {
                        ...prev,
                        filter: JSON.stringify(filtObj)
                    }
                })
                doIt.current = true
                setPageCount(0)
            }
        }
    }, [isFav])
    

    return (
        <div className={`flex flex-col space-y-4 relative ${tableType == 2 || tableType == 3 ? 'col-span-4 lg:col-span-2' : ''}`}>
            <div className="flex flex-row justify-between">
                <div className="flex flex-row space-x-2 items-center">
                    {
                        typeof title !== typeof undefined && (
                            <span className='text-[22px] font-semibold whitespace-nowrap'>{title}</span>
                        )
                    }
                    {/* <button className="but but-basic-emp hidden sm:block"><span className='whitespace-nowrap'>1/1/2024 - 1/3/2024</span></button> */}
                    {
                        tableType == 0 && (
                            <>
                                <PickDateRange dateRange={dateRange} setDateRange={setDateRange}  doIt={doIt} setPageCount={setPageCount} />
                                {/* <input className="inp w-[65px] bg-white text-center" disabled={true} /> */}
                                {/* <input className="inp w-[65px] bg-white text-center" placeholder="EXT" /> */}
                                {
                                    isFav ? (
                                        <button onClick={handleFav} className='but but-basic  active:text-blue-500'><FaStar className='' /></button>
                                    ) : (
                                        <button onClick={handleFav} className='but but-basic-emp border-blue-500 text-blue-500 hover:text-white hover:border-white active:text-blue-500'><CiStar className='' /></button>
                                    )
                                }
                                {/* <SelectNumber /> */}
                                {/* <SelectCountryNum isSm={true} /> */}
                            </>
                        )
                    }
                    <BubbleFilters filterParam={filterParam} setFilterParam={setFilterParam} allThs={allThs} />
                </div>
                <div className="flex flex-row space-x-2">

                    {
                        tableType === 0 && false && (
                            <button onClick={startAuto} className='but but-basic-color bg-green-600 hover:bg-green-700 active:bg-green-800 flex flex-row items-center space-x-1'>
                                <MdPhoneInTalk className='text-[18px]' />
                                <span>AUTO DIAL</span>
                            </button>
                        )
                    }
                    {
                        ButPop && (
                            <ButPop update_lead={update_lead} />
                        )
                    }

                    <button onClick={searchFilter} className={`but but-basic flex flex-row items-center space-x-1 ${isEmp() ? 'animate-custom-animation' : ''}`} disabled={!isEmp()}>
                        <BiFilterAlt />
                        <span className='font-semibold tracking-wide'>Filter</span>
                    </button>
                
                    {
                        (tableType == 1 && currentUser['access_level'] > 1) && (
                            <Link className="but but-basic-emp flex flex-row items-center space-x-1" to={'/users/create'}>
                                <FaPlus />
                                <span className='font-semibold tracking-wide'>create user</span>
                            </Link>
                        )
                    }
                </div>
            </div>
            <Table startPage={startPage} setStartPage={setStartPage} tableType={tableType} fetchURL={fetchURL} objFilter={objFilter} setObjFilter={setObjFilter} objBase={objBase} titles={allThs} data={data} setData={setData} setPageCount={setPageCount} scroledDiv={scroledDiv ? scroledDiv : scroledDiv2} isLoading={!isready.current} doIt={doIt} filterParam={filterParam} setFilterParam={setFilterParam} />
        </div>
    )
}



export default TableLayout;




