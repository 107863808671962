

const SelectStatusOne = ({selected, setSelected}) => {
    const data = [
        { value: 'New', label: 'New'},
        { value: 'Call again', label: 'Call again'},
        { value: 'No answer', label: 'No answer'},
        { value: 'Answer', label: 'Answer'},
        { value: 'Not Interested', label: 'Not Interested'},
        { value: 'Deposit', label: 'Deposit'},
        { value: 'In progress', label: 'In progress'},
        { value: 'Meeting', label: 'Meeting'},
        { value: 'Wrong Details', label: 'Wrong Details'},
    ];


    

    const handleListChange = (e) => {
        setSelected(e.target.value);
    };
    
    return (
        <div className='text-gray-800 text-[14px]'>
            <select onChange={handleListChange} value={selected} className="inp px-1 min-w-[120px]">
                {
                    data.map((item, i) => (
                        <option key={i} value={item.value}>
                            {item.label}
                        </option>
                    ))
                }
            </select>
        </div>
    )
}


export default SelectStatusOne