import React from 'react';
import { BrowserRouter as Router, Routes, Route  } from 'react-router-dom';

//Layouts
import PublicLayout from "../Layouts/publicLayout";
import PrivateLayout from "../Layouts/privateLayout";

//routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import AuthProtected from './AuthProtected';

import {SocketProvider} from './SocketProvider';
import { FavoritesProvider } from './FavoritesProvider';

const Index = () => {
    return (
        <React.Fragment>
            <Router>
                <Routes>
                    {publicRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <PublicLayout>
                                    {route.component}
                                </PublicLayout>
                            }
                            key={idx}
                            exact={true}
                        />
                    ))}
                {/* </Routes>

                <Routes> */}
                    {authProtectedRoutes.map((route, idx) => (
                        <Route
                            path={route.path}
                            element={
                                <AuthProtected>
                                    <FavoritesProvider>
                                        <SocketProvider>
                                            <PrivateLayout>
                                                {route.component}
                                            </PrivateLayout>
                                        </SocketProvider>
                                    </FavoritesProvider>
                                </AuthProtected>
                                }
                            key={idx}
                            exact={true}
                        />
                    ))}
                </Routes>
            </Router>
        </React.Fragment>
    );
};

export default Index;