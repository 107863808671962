import { createContext, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { currentUser0, status0 } from '../helpers/states';
import { useRecoilState } from 'recoil';

const SocketContext = createContext();

export const useSocket = () => {
    return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
    const [socketInstance, setSocketInstance] = useState(null);
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)
    const [status, setStatus] = useRecoilState(status0)


    useEffect(() => {
        const socket = io('https://azurecrm.co', {
            transports: ['websocket']
        });

        // Handle connection
        socket.on('connect', () => {
            console.log('Connected to server');

            if (currentUser?.['office_id'] && currentUser?.['extension'] && socket !== null) {
                socket?.emit('join_room', { room: currentUser?.['office_id'] + '_' + currentUser?.['extension'] });
            }
        });

        // Handle disconnection
        socket.on('disconnect', () => {
            setStatus(0)
            console.log('Disconnected from server');
        });

        setSocketInstance(socket)
    }, []);

    return (
        <SocketContext.Provider value={socketInstance}>
            {children}
        </SocketContext.Provider>
    );
};
