import Select from 'react-select';
import { useEffect, useState } from 'react';

const SelectDeskOne = ({deskData, selected, setSelected}) => {
    
    const handleListChange = (selectedOptions) => {
        setSelected(selectedOptions);
    };
    
    return (
        <div className='text-gray-800 text-[14px]'>
            <Select
                value={selected}
                onChange={handleListChange}
                closeMenuOnSelect={true}
                name="desk"
                options={deskData}
                className="basic-single z-20"
                classNamePrefix="select"
            />
        </div>
    )
}


export default SelectDeskOne