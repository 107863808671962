import Select from 'react-select';
import { officesList0 } from '../../helpers/states';
import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';

const SelectOfficeOne = ({selected, setSelected}) => {
    const [officesList, setOfficesList] = useRecoilState(officesList0)
    const [data, setData] = useState([{ value: null, label: '-----'}])
    
    useEffect(() => {
        if (officesList !== undefined) {
            setData(prev => {
                var list = officesList.map(office => {return { value: office.id, label: office.name}})
                list.unshift({value: null, label: '-----'})
                return list
            })
        }
    }, [officesList])

    const handleListChange = (selectedOptions) => {
        setSelected(selectedOptions);
    };
    
    return (
        <div className='text-gray-800 text-[14px]'>
            <Select
                defaultValue={selected}
                onChange={handleListChange}
                closeMenuOnSelect={true}
                name="office"
                options={data}
                className="basic-single z-20"
                classNamePrefix="select"
            />
        </div>
    )
}


export default SelectOfficeOne