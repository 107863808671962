import { IoMdSearch } from "react-icons/io";


const SearchInp = ({text, setText, white, placehold}) => {


    return (
        <div className={`flex flex-row ${white ? 'bg-white  shadow h-[36px]' : 'bg-gray-100'} items-center pl-3 rounded-md `}>
            <IoMdSearch className="text-gray-700" />
            <input placeholder={placehold} className={`inp border-none text-gray-800 ${white ? 'bg-white ' : 'bg-gray-100'}`} value={text} onChange={(e) => setText(e.target.value)} />
        </div>
    )
}

export default SearchInp