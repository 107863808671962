
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formDataToObject } from "../../../helpers/funcs";
import { post } from "../../../helpers/apiHandler";
import { currentUser0 } from "../../../helpers/states";

const CreateOfficePage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [errText, setErrText] = useState('')
    const Navigate = useNavigate()

    // const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    const createUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData(e.target);
        const obj = formDataToObject(formData);
        const res = await post('/office', obj);
        if (res.ok) {
            Navigate('/offices')
        } else {
            setErrText('Error during creation, please try again.');
        }
        setIsLoading(false);
    }


    return (
        <form className="card space-y-4 max-w-[1400px] mx-auto" onSubmit={createUser} autoComplete="off">
            <span className="text-[22px] font-semibold">
                Create Office
            </span>
            <div className="grid grid-cols-2 gap-4">
                <InpSpan span='name' name='name' type='text' required={true} />
                <InpSpan span='click2call' name='click2call' type='text' required={false} />
            </div>
            <div className="flex flex-row items-center justify-between">
                <span className="text-red-600 font-semibold text-[16px]">
                    {errText}
                </span>
                <div className="flex flex-row space-x-2">
                    <button className="but but-basic-emp" type="button" onClick={() => Navigate('/offices')}>cancel</button>
                    <button className="but but-basic" type="submit" disabled={isLoading}>create office</button>
                </div>
            </div>
        </form>
    )
}

export default CreateOfficePage;

const InpSpan = ({span, name, type, required}) => {

    return (
        <div className="flex flex-col space-y-1">
            <span className="font-semibold text-gray-800">{span}</span>
            <input className="inp" name={name} type={type} required={required} />
        </div>
    )
}
