import { useEffect, useState } from "react";
import SearchInp from "../../Components/handle_select_one/SearchInp";
import { useNavigate } from "react-router-dom";
import { TbBrandOffice } from "react-icons/tb";
import Skeleton from 'react-loading-skeleton'
import { get } from "../../helpers/apiHandler";
import { FaPlus } from "react-icons/fa6";
import { officesList0, currentUser0 } from "../../helpers/states";
import { useRecoilState } from "recoil";
import { fetchOffices } from "../../helpers/funcs";


const OfficesPage = () => {
    const Navigate = useNavigate()
    const [officesList, setOfficesList] = useRecoilState(officesList0)
    const [officesListAfter, setOfficesListAfter] = useState([])


    useEffect(() => {
        const fetchData = async () => {
            setOfficesList(await fetchOffices())
        }
        fetchData()
    }, [])

    const [textSearch, setTextSearch] = useState('')

    useEffect(() => {
        if (typeof officesList !== typeof undefined) {
            var res = officesList.filter(office => office.name.toLowerCase().includes(textSearch.toLowerCase()))
            setOfficesListAfter(res)
        }
    }, [textSearch, officesList])

    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    return (
        <div className="flex flex-col space-y-4 max-w-[1400px] mx-auto">
            <div className="card flex flex-row justify-between space-x-4">
                <div className="w-full max-w-[300px]">
                    <SearchInp placehold='Search office..' text={textSearch} setText={setTextSearch} />
                </div>

                {
                    currentUser.access_level === 4 && (
                        <button className="but but-basic flex flex-row items-center space-x-1" type="button" onClick={() => Navigate('/offices/create')}>
                            <FaPlus />
                            <span className='font-semibold tracking-wide'>Create office</span>
                        </button>
                    )
                }


            </div>


            <div className="grid grid-cols-2  md:grid-cols-3 lg:grid-cols-4 gap-4">
                {
                    officesListAfter?.map((office, o) => (
                        <div key={o} onClick={() => Navigate(`/offices/${office.id}`)} className="grow card hover:bg-blue-100 cursor-pointer border flex flex-col space-y-2 text-center items-center">
                            <TbBrandOffice className="text-[#405189] text-[50px]" />
                            <div className="flex flex-col">
                                <h1 className="font-semibold text-[20px]">{office.name}</h1>
                                <div className="flex flex-col space-y-0 items-center text-gray-500 text-sm font-semibold">
                                    <span className="">{office.agents_count} - users</span>
                                    <span className="">{office.desks_count} - desks</span>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    officesListAfter.length == 0 && typeof officesList !== typeof undefined && (
                        <span className="font-medium">
                            No results
                        </span>
                    )
                }
                {
                    typeof officesList == typeof undefined && (
                        <div className="card border flex flex-col space-y-2 text-center items-center">
                            <Skeleton className="h-[46px] w-[46px]" circle/>
                            <div className="flex flex-col">
                                <h1 className="font-semibold text-[20px]">
                                    <Skeleton className="w-[100px]" />
                                </h1>
                                <div className="flex flex-col space-y-0 items-center text-gray-500 text-sm font-semibold">
                                    <div className="flex flex-row items-center space-x-1">
                                        <Skeleton className="w-4" />
                                        <span className="flex flex-row space-x-2"> - users</span>
                                    </div>
                                    <div className="flex flex-row items-center space-x-1">
                                        <Skeleton className="w-4" />
                                        <span className="flex flex-row space-x-2"> - leads</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>

        </div>
    )
}

export default OfficesPage;