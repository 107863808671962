import { useRecoilState } from "recoil";
import accessLevel from "../../docs/accessLevel";
import { currentUser0 } from "../../helpers/states";


const SelectLevelOne = ({selected, setSelected}) => {
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    var data = []
    for (var x=0; x<currentUser.access_level; x++) {
        data.push(accessLevel[x])
    }

    const handleListChange = (e) => {
        if (typeof setSelected !== typeof undefined) {
            setSelected(e.target.value);
        }
    };
    
    return (
        <div className='text-gray-800 text-[14px]'>
            <select onChange={handleListChange} defaultValue={selected} className="inp px-1 min-w-[120px]" name="access_level">
                {
                    data.map((item, i) => (
                        <option key={i} value={item.value}>
                            {item.label}
                        </option>
                    ))
                }
            </select>
        </div>
    )
}


export default SelectLevelOne