import Select from 'react-select';
import IsoToLang from '../../docs/IsoToLang';
import { useEffect } from 'react';


const SelectLang = ({selected, setSelected}) => {
    var data = Object.keys(IsoToLang).map(isoCode => {
        return {value: isoCode, label: IsoToLang[isoCode]}
    })
    data.unshift({value: null, label: '-----'})
    

    const handleListChange = (selectedOptions) => {
        setSelected(selectedOptions);
    };

    const customStyles = {
        control: (provided, state) => ({
          ...provided,
          backgroundColor: '#f3f4f6',
        }),
        menuList: base => ({
            ...base,
            maxHeight: "200px"
        })
    };
    
    return (
        <div className='text-gray-800 text-[14px]'>
            <Select
                defaultValue={selected}
                onChange={handleListChange}
                styles={customStyles}
                isMulti
                closeMenuOnSelect={false}
                name="colors"
                options={data}
                className="basic-multi-select z-50"
                classNamePrefix="select"
            />
        </div>
    )
}


export default SelectLang