import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formDataToObject } from "../../../helpers/funcs";
import SelectLevelOne from "../../../Components/handle_select_one/SelectLevelOne";
import { post } from "../../../helpers/apiHandler";
import SelectOfficeOne from "../../../Components/handle_select_one/SelectOfficeOne";
import SelectDeskOne from "../../../Components/handle_select_one/SelectDeskOne";
import { useRecoilState } from "recoil";
import { leadsTable0, officesList0 } from "../../../helpers/states";

const CreateUserPage = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [errText, setErrText] = useState('')
    const Navigate = useNavigate()
    const [leadsTable, setLeadsTable] = useRecoilState(leadsTable0)

    const createUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData(e.target);
        const obj = formDataToObject(formData);
        
        const res = await post('/user', {...obj, access_level: selectedLevel.value});
        if (res.ok) {
            setLeadsTable({"leads": undefined,"users": undefined});
            Navigate('/users')
        } else {
            setErrText('Error during creation, please try again.');
        }
        setIsLoading(false);
    }

    const [selectedLevel, setSelectedLevel] = useState({ value: '1', label: 'agent'})




    ///////////////// OFFICE //////////////
    const [officesList, setOfficesList] = useRecoilState(officesList0)
    const [selectedOffice, setSelectedOffice] = useState({value: null, label: '-----'})
    const [selectedDesk, setSelectedDesk] = useState({value: null, label: '-----'})
    const [deskData, setDeskData] = useState([{value: null, label: '-----'}])
    useEffect(() => {
        if (selectedOffice !== undefined) {
            setSelectedDesk({value: null, label: '-----'})
            if (selectedOffice.value === null) {
                setDeskData([{value: null, label: '-----'}])
            } else {
                const office = officesList.find(item => item.id === selectedOffice.value)
                setDeskData([{value: null, label: '-----'}, ...office?.desks.map(des => {return {'value': des.id, 'label': des.name}})])
            }
        }
    }, [selectedOffice])


    return (
        <form className="card space-y-4 max-w-[1400px] mx-auto" onSubmit={createUser} autoComplete="off">
            <span className="text-[22px] font-semibold">
                Create User
            </span>
            <div className="grid grid-cols-2 gap-4">
                <InpSpan span='name' name='name' type='text' required={true} />
                <InpSpan span='email' name='email' type='email' required={true} />
                <InpSpan span='password' name='password' type='password' required={true} />

                <div className="flex flex-col space-y-1">
                    <span className="font-semibold text-gray-800">level</span>
                    <SelectLevelOne selected={selectedLevel} setSelected={setSelectedLevel} />
                </div>

                <div className="flex flex-col space-y-1">
                    <span className="font-semibold text-gray-800">office</span>
                    <SelectOfficeOne selected={selectedOffice} setSelected={setSelectedOffice} />
                </div>

                <div className="flex flex-col space-y-1">
                    <span className="font-semibold text-gray-800">desk</span>
                    <SelectDeskOne deskData={deskData} selected={selectedDesk} setSelected={setSelectedDesk} />
                </div>



                <InpSpan span='extension' name='extension' type='number' required={false} />
            </div>
            <div className="flex flex-row items-center justify-between">
                <span className="text-red-600 font-semibold text-[16px]">
                    {errText}
                </span>
                <div className="flex flex-row space-x-2">
                    <button className="but but-basic-emp" type="button" onClick={() => Navigate('/users')}>cancel</button>
                    <button className="but but-basic" type="submit" disabled={isLoading}>create user</button>
                </div>
            </div>
        </form>
    )
}

export default CreateUserPage;

const InpSpan = ({span, name, type, required}) => {

    return (
        <div className="flex flex-col space-y-1">
            <span className="font-semibold text-gray-800">{span}</span>
            <input className="inp" name={name} type={type} required={required} />
        </div>
    )
}
