import { IoClose } from "react-icons/io5";


const Model = ({children, setState, title}) => {
    const handleClickOutside = (event) => {
        if (event.target === event.currentTarget) {
            setState(false);
        }
    };

    return (
        <div onClick={handleClickOutside} className="fixed top-0 left-0 w-full h-full z-50 flex items-center justify-center bg-gray-600 bg-opacity-40 !mt-0">
            <div className="card space-y-4">
                <div className="flex flex-row justify-between items-center">
                    <span className="font-semibold text-[18px]">{title}</span>
                    <button className="" onClick={() => setState(false)}>
                        <IoClose className="text-[20px] text-gray-600" />
                    </button>
                </div>
                {children}
            </div>
        </div>
    )
}
export default Model