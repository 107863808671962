


export const ThsFilter = [
    {key: 'first_name', display: 'first name'},
    {key: 'last_name', display: 'last name'},
    {key: 'brand', display: 'brand'},
    {key: 'email', display: 'email'},
    {key: 'status', display: 'status'},
    {key: 'country', display: 'country'},
    {key: 'lang', display: 'language'},
    {key: 'original_agent', display: 'original agent'},
    {key: 'lead_source', display: 'lead source'},
    {key: 'file_name', display: 'file name'},
    {key: 'favorite', display: 'favorite'}
]

export const bankColor = {
    'New': 'rgb(255 255 255)',
    'Call again': 'rgb(233 234 251)',
    'No answer': 'rgb(250 241 226)',
    'Answer': 'rgb(252 245 95)',
    'Not Interested': 'rgb(250 202 203)',
    'Wrong Details': 'rgb(250 225 226)',
    'Deposit': 'rgb(182 237 230)',
    'In progress': 'rgb(226 248 236)',
    'Meeting': 'rgb(227 248 252)'
}

export const bankColor2 = {
    'Call again': '#2686d2',
    'No answer': '#ff7a4d',
    'Answer': '#FFEA00',
    'Not Interested': '#ff4d4d',
    'Wrong Details': '#ff0000',
    'Deposit': '#4eef97',
    'In progress': '#6ebe3d',
    'Meeting': '#67d9ef'
}
