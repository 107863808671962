import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { get } from '../helpers/apiHandler';
import { useRecoilState } from 'recoil';
import { currentUser0 } from '../helpers/states';
import Loading from '../Layouts/loading';


const AuthProtected = ({ children }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [currentUser, setCurrentUser] = useRecoilState(currentUser0)

    useEffect(() => {
        const checkAuthentication = async () => {
            if (localStorage.getItem('authToken')) {
                if (currentUser == null) {
                    const result = await get('/user');
                    if (result.ok) {
                        setCurrentUser(result.data)
                    } else {
                        localStorage.removeItem('authToken');
                        setIsAuthenticated(false);
                        navigate('/login');
                        return;
                    }
                }
                setIsAuthenticated(true);
                return;
            }
            setIsAuthenticated(false);
            navigate('/login');
        };

        checkAuthentication();
    }, [navigate]);

    return isAuthenticated ? children : <Loading />;
};

export default AuthProtected;
