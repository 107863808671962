import { useLocation } from 'react-router-dom';
import TableLayout from '../../Components/handle_table/TableLayout';
import { pos0 } from '../../helpers/states';
import { useRecoilState } from 'recoil';
import { useEffect, useRef } from 'react';

const UsersPage = () => {


    // handle pos X
    const [pos, setPos] = useRecoilState(pos0)
    const scroledDiv = useRef(null);
    useEffect(() => {
        function handleScroll() {
            if (scroledDiv.current) {
                const { scrollTop } = scroledDiv.current;
                if (scrollTop > 10) {
                    setPos(scrollTop)
                }
            }
        }
        if (scroledDiv.current) {
            scroledDiv.current.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (scroledDiv.current) {
                scroledDiv.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);
    const location = useLocation()
    useEffect(() => {
        scroledDiv.current.scrollTop = pos;
    }, [location])
    //

    return (
        <TableLayout objBase={objBase} allThs={allThs} fetchURL={'/users'} tableType={1} scroledDiv={scroledDiv} />
    )
}



export default UsersPage;



const objBase = {"id":[],"name":[],"email":[],"office_name":[],"desk_name":[],"extension":[],"access_level":[]}

const allThs = [
    {key: 'online', display: 'online'},
    {key: 'id', display: 'id'},
    {key: 'name', display: 'name'},
    {key: 'email', display: 'email'},
    {key: 'office_name', display: 'offices'},
    {key: 'desk_name', display: 'desks'},
    {key: 'extension', display: 'extension'},
    {key: 'access_level', display: 'level'},
]


// {"sort_by":"id","sort_direction":"asc","per_page":50,"filter":"{\"id\":[],\"name\":[],\"email\":[],\"office_name\":[{\"operator\":\"eq\",\"value\":\"Fashion Brand\",\"join\":\"and\"}],\"extension\":[],\"access_level\":[]}"}