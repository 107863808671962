import { createContext, useContext, useEffect, useState } from 'react';
import { fetchFavorites } from '../helpers/funcs';
import { Store } from 'react-notifications-component';
import { put } from '../helpers/apiHandler';


const FavoritesContext = createContext();


export const useFavorites = () => {
    return useContext(FavoritesContext); // FavoritesContext צריך להיות מוגדר כאן
};

export const FavoritesProvider = ({ children }) => {
    const [favoritesInstance, setFavoritesInstance] = useState(null);

    const fetchData = async () => {
        const fav = await fetchFavorites();
        setFavoritesInstance(fav);
    };

    const addFavorite = (id) => {
        setFavoritesInstance((prev) => {
            return {
                [id]: true,
                ...prev
            }
        });
    };

    const removeFavorite = (id) => {
        setFavoritesInstance((prev) => {
            var newObj = {...prev}
            delete newObj[id]
            return newObj
        });
    };

    const handleFavorite = async (id) => {
        id = parseInt(id)
        const isFav = typeof favoritesInstance?.[id] === typeof undefined;

        const result = await put(`/lead/${id}`, {favorite: isFav})

        if (result.ok) {

            if (isFav) {
                addFavorite(id)
            } else {
                removeFavorite(id)
            }

            Store.addNotification({
                message: "Lead successfully updated",
                type: "success",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                duration: 5000,
                }
            });
        } else {
            Store.addNotification({
                message: result.data.message || `somthing went wrong`,
                type: "danger",
                insert: "bottom",
                container: "bottom-right",
                dismiss: {
                duration: 5000,
                }
            });
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <FavoritesContext.Provider
            value={{
                favorites: favoritesInstance,
                handleFavorite
            }}
        >
            {children}
        </FavoritesContext.Provider>
    );
};
