

import { useEffect, useState, useRef } from 'react';
import { BiFilterAlt } from 'react-icons/bi';
import { HiFilter} from 'react-icons/hi'
import SelectStatus from '../handle_select/SelectStatus';
import { areListEqual } from '../../helpers/funcs';
import SelectCountry from '../handle_select/SelectCountry';
import SelectLang from '../handle_select/SelectLang';
import SelectLevels from '../handle_select/SelectLevels';
import SelectOffices from '../handle_select/SelectOffices';
import SelectDesks from '../handle_select/SelectDesks';

const BoxFillter = ({randomId, titleKey, filterParam, setFilterParam, tableType}) => {
    const [show, setShow] = useState(false)

    useEffect(() => {
        if (show) {
            const handleBodyClick = (e) => {
                var element = e.target
                var isOut = true;
                while (true) {
                    if (element.id == randomId || element.id == `but-${randomId}`) {
                        isOut = false;
                        break;
                    };

                    try {
                        if (element.getAttribute('class').includes('select')) isOut = false;
                    } catch (e) {}

                    element = element.parentElement
                    if (element == null) break;
                }
                if (isOut) setShow(false);
            };
                document.body.addEventListener('click', handleBodyClick);
            return () => {
                document.body.removeEventListener('click', handleBodyClick);
            };
        }
    }, [show])







    ///////// STATUS ///////////
    const [selectedStatus, setSelectedStatus] = useState([])
    useEffect(() => {
        if (tableType == 0) {
            setFilterParam(prev => {
                if (!areListEqual(prev['status'], selectedStatus)) {
                    return {...prev, status: selectedStatus}
                }
                return prev;
            })
        }
    }, [selectedStatus])
    useEffect(() => {
        if (titleKey === 'status') {
            if (!areListEqual(filterParam['status'], selectedStatus)) {
                setSelectedStatus(filterParam['status'])
            }
        }
    }, [filterParam.status])

    ///////// COUNTRY ///////////
    const [selectedCountry, setSelectedCountry] = useState([])
    useEffect(() => {
        if (tableType == 0) {
            setFilterParam(prev => {
                if (!areListEqual(prev['country'], selectedCountry)) {
                    return {...prev, country: selectedCountry}
                }
                return prev;
            })
        }
    }, [selectedCountry])
    useEffect(() => {
        if (titleKey === 'country') {
            if (!areListEqual(filterParam['country'], selectedCountry)) {
                setSelectedCountry(filterParam['country'])
            }
        }
    }, [filterParam.country])

    ///////// LANGUAGE ///////////
    const [selectedLang, setSelectedLang] = useState([])
    useEffect(() => {
        if (tableType == '0') {
            setFilterParam(prev => {
                if (!areListEqual(prev['lang'], selectedLang)) {
                    return {...prev, lang: selectedLang}
                }
                return prev;
            })
        }
    }, [selectedLang])
    useEffect(() => {
        if (titleKey === 'lang') {
            if (!areListEqual(filterParam['lang'], selectedLang)) {
                setSelectedLang(filterParam['lang'])
            }
        }
    }, [filterParam.lang])



    ////////////////////////////////////////////////////// USERS TABLE ///////////////////////////////////////////
    ///////// LEVEL ///////////
    const [selectedLevel, setSelectedLevel] = useState([])
    useEffect(() => {
        if (tableType == '1') {
            setFilterParam(prev => {
                if (!areListEqual(prev['access_level'], selectedLevel)) {
                    return {...prev, access_level: selectedLevel}
                }
                return prev;
            })
        }
    }, [selectedLevel])
    useEffect(() => {
        if (titleKey === 'access_level') {
            if (!areListEqual(filterParam['access_level'], selectedLevel)) {
                setSelectedLevel(filterParam['access_level'])
            }
        }
    }, [filterParam.access_level])


    ///////// OFFICES ///////////
    const [selectedOffices, setSelectedOffices] = useState([])
    useEffect(() => {
        if (tableType == '1') {
            setFilterParam(prev => {
                if (!areListEqual(prev['office_name'], selectedOffices)) {
                    return {
                        ...prev,
                        office_name: selectedOffices.map(office => {return {operator: 'eq', value: office.value, label: office.label, join: 'or'}})
                    }
                }
                return prev;
            })
        }
    }, [selectedOffices])
    useEffect(() => {
        if (titleKey === 'office_name') {
            if (!areListEqual(filterParam['office_name'], selectedOffices)) {
                setSelectedOffices(filterParam['office_name'])
            }
        }
    }, [filterParam.office_name])


    ///////// DESKS ///////////
    const [selectedDesks, setSelectedDesks] = useState([])
    useEffect(() => {
        if (tableType == '1') {
            setFilterParam(prev => {
                if (!areListEqual(prev['office_name'], selectedDesks)) {
                    return {
                        ...prev,
                        desk_name: selectedDesks.map(desk => {return {operator: 'eq', value: desk.value, label: desk.label, join: 'or'}})
                    }
                }
                return prev;
            })
        }
    }, [selectedDesks])
    useEffect(() => {
        if (titleKey === 'desk_name') {
            if (!areListEqual(filterParam['desk_name'], selectedDesks)) {
                setSelectedDesks(filterParam['desk_name'])
            }
        }
    }, [filterParam.desk_name])




    return (
        <div className=''>
            <div className="hoverEffect" onClick={() => setShow(prev => !prev)} id={'but-' + randomId}>
                {
                    filterParam[titleKey]?.length == 0 || typeof filterParam[titleKey] == typeof undefined ? (
                        <HiFilter className="text-[#a9b7e7] relative top-[1px]" />
                        ) : (
                        <BiFilterAlt className='text-[#a9b7e7] relative top-[1px]' />
                    )
                }
            </div>
            {
                show && (
                    <div className='absolute font-medium top-[40px] w-[200px]'> {/* bg-blue-500  */}
                        <div id={randomId} className='card border-2 p-2 flex flex-col space-y-2 relative right-[90px]'> {/* bg-red-600  */}
                            {
                                titleKey === 'status' ? (
                                    <SelectStatus selected={selectedStatus} setSelected={setSelectedStatus} />
                                ) : (

                                titleKey === 'country' ? (
                                    <SelectCountry selected={selectedCountry} setSelected={setSelectedCountry} />
                                ) : 

                                titleKey === 'lang' ? (
                                    <SelectLang selected={selectedLang} setSelected={setSelectedLang} />
                                ) : 

                                titleKey === 'access_level' ? (
                                    <SelectLevels selected={selectedLevel} setSelected={setSelectedLevel} />
                                ) : 

                                titleKey === 'office_name' ? (
                                    <SelectOffices selected={selectedOffices} setSelected={setSelectedOffices} />
                                ) : 

                                titleKey === 'desk_name' ? (
                                    <SelectDesks selected={selectedDesks} setSelected={setSelectedDesks} />
                                ) : 
                                
                                
                                    (
                                        <>
                                            {
                                                filterParam[titleKey]?.map((item, i) => (
                                                    <LittleBox key={i} index={i} item={item} setFilterParam={setFilterParam} titleKey={titleKey} />
                                                ))
                                            }{
                                                (filterParam[titleKey]?.length == 0 || filterParam[titleKey][filterParam[titleKey].length-1]?.value != '' && typeof filterParam[titleKey][filterParam[titleKey].length-1]?.value != typeof undefined) && (
                                                    <LittleBox item={{operator: 'contains', value: '', join: 'and'}} setFilterParam={setFilterParam} titleKey={titleKey} filterParam={filterParam} />
                                                )
                                            }
                                        </>
                                    )
                                )
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default BoxFillter

const LittleBox = ({setFilterParam, titleKey, item, index, filterParam}) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (typeof index !== typeof undefined) {
            inputRef.current.focus();
        }
    }, []);
    useEffect(() => {
        try {
            if (filterParam[titleKey].length == 0) {
                inputRef.current.focus();
            }
        } catch (e) {}
    }, [filterParam])

    const datesList = ['registration_time', 'created_at', 'ftd_date', 'birthday']
    const handleChangeText = (e) => {
        setFilterParam(prev => {
            if (e.target.value == '' && typeof index !== typeof undefined ) { // && [...prev[titleKey]].length != 1
                var list = [...prev[titleKey]]
                var newList = list.filter((item, i) => (i !== index));
                return {...prev, [titleKey]: newList}  
            } else {
                if (typeof index !== typeof undefined) {
                    var obj = {...prev[titleKey][index], value: e.target.value}
                    var list = [...prev[titleKey]]
                    list[index] = obj
                } else {
                    var list = []
                    if (typeof prev[titleKey] !== typeof undefined) {
                        list = [...prev[titleKey]]
                    }
                    var obj = {...item, value: e.target.value}
                     list.push(obj)
                }
                return {...prev, [titleKey]: list}       
            }
        })
    }

    const handleChangeRadio = (e) => {
        setFilterParam(prev => {
            var list = [...prev[titleKey]]
            list[index] = {...list[index], join: e.target.value}
            return {...prev, [titleKey]: list}
        })
    }
    
    const handleChangeOperator = (e) => {
        setFilterParam(prev => {
            var list = [...prev[titleKey]]
            list[index] = {...list[index], operator: e.target.value}
            return {...prev, [titleKey]: list}
        })
    }
    


    return (
        <div className={`flex flex-col space-y-2`}>
            <select className='inp' defaultValue={item.operator} onChange={handleChangeOperator} disabled={typeof index == typeof undefined}>
                <option value="contains">Contains</option>
                <option value="ncontains">Not Contains</option>
                <option value="eq">Equals</option>
                <option value="neq">Not Equals</option>
                <option value="gt">Greather than</option>
                <option value="lt">Lower than</option>
            </select>
            <input ref={inputRef} type={datesList.includes(titleKey) ? 'date' : 'text'} className='inp' placeholder='Filter...' value={item.value} onChange={handleChangeText} />
            {
                typeof index !== typeof undefined && (
                    <div className='flex flex-row text-gray-900 justify-around text-[12px] font-bold'>
                        <label className='flex flex-row items-center space-x-1'>
                            <input
                                type="radio"
                                value="and"
                                checked={item.join === 'and'}
                                onChange={handleChangeRadio}
                            />
                            <span>AND</span>
                        </label>
                        <label className='flex flex-row items-center space-x-1'>
                            <input
                                type="radio"
                                value="or"
                                checked={item.join === 'or'}
                                onChange={handleChangeRadio}
                            />
                            <span>OR</span>
                        </label>
                    </div>
                )
            }
        </div>
    )
}